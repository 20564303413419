<template>
    <v-container>
        <v-row>
            <v-breadcrumbs large :items="items_nav">
                <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                <v-breadcrumbs-item :disabled="item.disabled">
                    {{ item.text }}
                </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>
        <v-card elevation="4">
            <v-card-title>
                <v-row>
                    <div class="col">
                        Novo Fornecedor
                    </div>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                  <!-- <v-row>
                    <v-col cols="12"  md="4" >
                      <v-card elevation="4" height="160" width="160">
                        <v-container grid-list-sm fluid>
                          <v-layout row wrap>
                            <v-img
                              aspect-ratio="1"
                            >              
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="white lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>        
                            </v-img>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row> -->
                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="fornecedor.nome"
                                counter="50"
                                maxlength="50"
                                label="Nome do Representante"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="fornecedor.email"
                                counter="40"
                                maxlength="40"
                                label="Email"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="fornecedor.nome_empresa"
                                counter="30"
                                maxlength="30"
                                label="Fornecedor (nome que será exibido)"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="fornecedor.categoria_id"
                            item-text="nome"
                            item-value="id"
                            :items="getCategorias"
                            label="Categoria"
                            
                          >
                          </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                      <!-- <v-col cols="12"  md="3" class="text-center">
                            <v-text-field
                                v-model="fornecedor.tag"
                                counter="14"
                                maxlength="14"
                                label="Tag"
                                required
                            ></v-text-field>
                        </v-col> -->
                        <v-col cols="12"  md="4" class="text-center">
                            <v-text-field
                                v-model="fornecedor.onde_conheceu"
                                counter="30"
                                maxlength="30"
                                label="Onde conheceu"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="4" class="text-center">
                            <v-text-field
                                v-model="fornecedor.telefone"
                                counter="14"
                                maxlength="14"
                                label="Telefone"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="4" class="text-center">
                          <v-select
                            v-model="fornecedor.uf"
                            item-text="id"
                            item-value="nome"
                            :items="getUFs"
                            label="UF"
                            return-object
                          >
                          </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-action class="d-flex flex-row-reverse">
                <div class="ma-4">
                        <v-btn
                            color="success"
                            dark
                            @click="cadastrar()"
                            >
                            SALVAR
                        </v-btn>
                    </div>
            </v-card-action>
        </v-card>

        <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Realizando operação por favor aguarde...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMessage" width="480">
          <v-card class="mx-auto" dark>
            <v-card-title>
                <span class="title font-weight-light">{{ message.title }}</span>
            </v-card-title>
            <v-card-text class="headline font-weight-bold">
                {{ message.body }}
            </v-card-text>
            </v-card>
        </v-dialog>


    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios'

export default {
    data() {
        return {
            items_nav: [
                {
                    text: "Sistema",
                    disabled: false,
                },
                {
                    text: "Fornecedores",
                    disabled: true,
                },
                {
                    text: "Novo",
                    disabled: true,
                }
            ],
            fornecedor: {
              nome: "",
              email: "",
              onde_conheceu: "",
              categoria_id: "",
              nome_empresa: "",
              is_facebook: false,
              telefone: "",
              uf: ""
            },
            dialogProgress: false,
            message: {
              title: "",
              body: ""
            },
            dialogMessage: false,
      }
    },
    computed: {
    ...mapGetters(["getCategorias", "getUFs"]),
  },
    methods: {
      cleanFornecedor: async function() {
        this.fornecedor = {
              nome: "",
              email: "",
              onde_conheceu: "",
              categoria_id: "",
              nome_empresa: "",
              is_facebook: false,
              telefone: "",
              uf: ""
            }
      },
      cadastrar: async function() {
        this.dialogProgress = true;
        var result;
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Environment': 'prod'
            };
            result = await axios.post("https://us-central1-festit-c0636.cloudfunctions.net/api/fornecedores/save", this.fornecedor, { headers })
            if(result.status == 200) {
              this.dialogProgress = false;
              this.message.title = "Operação realizada";
              this.message.body = "Fornecedor cadastrado com sucesso!";
              this.cleanFornecedor();
              this.dialogMessage = true;
            } else {
              var objeto = await result.json();
              this.dialogProgress = false;
              this.message.title = "Operação falhou";
              this.message.body = objeto.message;
              this.dialogMessage = true;
            }
        } catch (error) {
            this.dialogProgress = false;
            this.message.title = "Operação falhou";
            this.message.body = "Verifique sua conexão e tente novamente!";
            this.dialogMessage = true;
        }
      }
    },
}
</script>

<style>

</style>